<template>
  <div style="background: #F7F9FC;color: #000;height: 100%;">
    <van-nav-bar title="我的奖品" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div style="margin-top:50px;height: 100%;background: #F7F9FC;">
      <div class="mian" v-for="(item,idx) in list" :key="idx">
        <div class="xianmu">
          <div class="xianmu_A left">
            <span v-if="item.type == 1">赠送套餐：{{item.pname}}</span>
            <span v-if="item.type == 2">赠送优惠券：{{item.pname}}</span>
            <span v-if="item.type == 3">赠送储值卡：{{item.pname}}元</span>
            <span v-if="item.type == 4">赠送积分：{{item.pname}}分</span>
            <span v-if="item.type == 5">线下奖品：{{item.pname}}</span>
          </div>
          <van-button
            class="anniu"
            v-if="item.status == 1"
            plain
            type="danger"
            round
            @click="toma(item)"
          >领取</van-button>
          <div class="duihuan" v-if="item.status == 2">已兑换</div>
        </div>
        <div class="shijian">
          <span class="left">抽奖时间：{{item.createdDate}}</span>
          <span class="right" style="color: #666;">领取方式：{{item.getType == 0?"线上":'线下'}}</span>
        </div>
      </div>
      <!-- <van-empty v-if="list.length < 1" description="暂无数据~" /> -->
    </div>
  </div>
</template>

<script>
import orderModel from "../../api/order";
import utils from "../../assets/utils";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  //   mixins: [preventBack], //注入
  components: {},
  data() {
    return {
      list: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      active: "0"
    };
  },
  methods: {
    toma(item) {
      if (item.getType == 1) {
        this.$router.push({
          path: "Lucky_ma",
          query: {
            id: item.id,
            type: item.type,
            pname:item.pname
          }
        });
      } else {
        const data = {
          id: item.id
        };
        orderModel
          .linquprize(data)
          .then(e => {
            this.$toast("领取成功");
            setTimeout(() => {
              this.onLoad();
            }, 700);
          })
          .catch(() => loading.clear());
      }
    },
    onLoad() {
      const data = {
        gid: this.shangjia.id,
        customerId: this.userInfo.customerId,
        // gid: 1,
        // customerId: 205
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      orderModel
        .drawlist(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },

    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.mian {
  margin: 12px 12px;
  background: #fff;
  //   padding-bottom: 5px;
  overflow: hidden;
  border-radius: 5px;
}
.duihuan {
  height: 20px;
  width: 56px;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  margin-top: 10px;
  border: 1px solid #999;
  border-radius: 10px;
  color: #999;
  float: right;
}
.anniu {
  height: 20px;
  font-size: 12px;
  float: right;
  margin-top: 10px;
}
.chepaih {
  display: inline-block;
  background: #000093;
  color: #fff;
  padding: 0 2px;
}
.xianmu {
  padding: 0 12px;
  overflow: hidden;
  font-size: 14px;
  color: #000;
  border-bottom: 1px solid #f5f5f5;
}
.xianmu_A {
  line-height: 40px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
}
.xianmu_b {
  line-height: 25px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 12px;
}
.jiesuan {
  line-height: 25px;
  color: red;
  font-size: 15px;
  text-align: center;
}
.jies {
  display: inline-block;
  border: 2px solid red;

  padding: 0 35px;
}
.shijian {
  font-size: 12px;
  color: #666;
  line-height: 28px;
  padding: 0 12px;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
</style>